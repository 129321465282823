<template>
  <div class="">
    <span v-if="selectNum==1">
      <div @click="chooseFile()" class="default">
        <el-image v-if="[-1, undefined].includes(selectData[0]?.indexOf('.mp4'))" shape="square" :size="100" :fit="fit" :src="selectData?selectData[0]:''" icon="el-icon-upload2" style="width: 100px; height: 100px; background-color: #f5f5f5;">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image v-else shape="square" :size="100" :fit="fit" :src="require('@/assets/images/mp4.svg')" icon="el-icon-upload2" style="width: 100px; height: 100px; background-color: #f5f5f5;">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div class="cover">重新选择</div>
      </div>
    </span>
    <span v-else>
      <div class="picbox" v-if="selectData && selectData.length > 0">
        <div @click="chooseFile()" class="item" v-for="(item, index) in selectData" :key="index">
          <el-image shape="square" :size="100" :fit="fit" :src="item" icon="el-icon-upload2" style="width: 100px; height: 100px; background-color: #f5f5f5;">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div @click="chooseFile()" class="default">
          <el-image shape="square" :size="100" :fit="fit" :src="avatar" icon="el-icon-upload2" style="width: 100px; height: 100px; background-color: #f5f5f5;">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
      <div v-else @click="chooseFile()" class="default">
        <el-image shape="square" :size="100" :fit="fit" :src="avatar" icon="el-icon-upload2" style="width: 100px; height: 100px; background-color: #f5f5f5;">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </span>
    <el-dialog title="管理文件" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" custom-class="dialog" width="740px" :append-to-body="true">
      <FileUpload :fileType="selectType" :chooseNum="selectNum" :chooseFunc="fileChoose"></FileUpload>
    </el-dialog>
  </div>
</template>

<script>
  import FileUpload from './FileUpload'
  export default {
    data() {
      return {
        dataList: false,
        dialogFormVisible: false,
        avatar: '',
        fit: 'contain'
      }
    },
    components: {
      FileUpload
    },
    props: {
      selectType: {
        type: [Number, Array],
        default: 1
      },
      selectNum: Number,
      selectFunc: Function,
      selectData: Array,
      idx: Number
    },
    created() {
    },
    mounted() {
    },
    methods: {
      chooseFile() {
        this.dialogFormVisible = !this.dialogFormVisible
      },
      fileChoose(data) {
        this.selectFunc(data,this.idx)
        this.chooseFile()
      }
    }
  }
</script>

<style scoped>
  .picbox .item,.default { float: left; margin: 0 10px 10px 0; cursor: pointer; position: relative;}
  .default .cover { position: absolute; bottom: 10px; height: 30px; line-height: 30px; text-align: center; color: #fff; background-color: rgb(22 21 21 / 50%); width: 100%; border-radius: 0 0 4px 4px;}
</style>
