<template>
  <div class="">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item>
        <el-upload
                ref="upload"
                :action="uploadUrl"
                name="file"
                :data="picdata"
                :show-file-list="false"
                :multiple="true"
                :headers="headers"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError">
          <el-button icon="el-icon-upload2">上传文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-input placeholder="请输入" class="input-with-select" clearable v-model="search.keywords">
          <el-button slot="append" @click="handleSearch()">搜索</el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
          <el-button type="warning" @click="optChange('open')" v-if="!isDelStatus">批量操作</el-button>
          <el-button type="danger" @click="delImg()" v-if="isDelStatus">批量删除</el-button>
          <el-button type="info" @click="optChange('close')" v-if="isDelStatus">取消</el-button>
      </el-form-item>
    </el-form>
    <div class="picList mg-tp-20">
      <ul>
        <li v-for="(item, index) in dataList" v-bind:key="index" @click="chooseFile(item)" :class="{ 'active': item.active}">
          <div class="image-img">
            <img :src="item.pic">
            <div class="image-meta">{{item.title}}</div>
            <span class="spot"><i class="el-icon-check"></i></span>
          </div>
        </li>
      </ul>
    </div>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :current-page.sync="search.current_page"
        :page-size="27"
        :total="search.total">
    </el-pagination>
    <el-button type="primary" size="small" @click="chooseOk()" style="position: absolute; bottom: 35px; right: 30px;" v-if="!isDelStatus">确定</el-button>
  </div>
</template>

<script>
  import { Loading } from 'element-ui';
  import config from "../config/config";
  import {getToken} from "@/utils/auth";

  export default {
    data() {
      return {
        uploadUrl: config.HTTP_REQUEST_URL + 'admin/1.0/common/uploadimg',
        headers: {
          authorization: 'Bearer ' + getToken()
        },
        picdata: {
          type: 'file'
        },
        dataList: false,
        search: {
          current_page: 1,
          total: 0,
          limit: 21,
          keywords: '',
          file_type: this.fileType
        },
        chooseList: [],
        fileNum: this.chooseNum,
        isDelStatus: false,
        fileNumTmp: 0
      }
    },
    props: {
      fileType: {
        type: [Array, Number],
        default: 1
      },
      chooseNum: Number,
      chooseFunc: Function
    },
    created() {
      this.chooseList = []
      this.search.current_page = 1
      this.search.keywords = ''
    },
    mounted() {
      this.getList()
    },
    methods: {
      optChange(type) {
        if(type=='open') {
          this.isDelStatus = true
          this.fileNumTmp = this.fileNum
          this.fileNum = 100
        } else {
          this.isDelStatus = false
          this.fileNum = this.fileNumTmp
        }
      },
      delImg() {
        if(this.isEmpty(this.chooseList)) {
          this.$message.error('请选择图片!')
          return false
        }
        var ids = []
        this.chooseList.forEach(item => {
          ids.push(item.id)
        })
        this.$api.merchant.filedel({ids:ids},res=>{
          this.$message.success('删除成功')
          this.getList()
        })
      },
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          keywords: that.search.keywords,
          file_type: typeof that.search.file_type === 'number' ? [that.search.file_type] : that.search.file_type
        }
        this.$api.merchant.fileList(param, function (res) {
          that.hideLoading()
          if(res.errcode == 0) {
            that.dataList = res.data.data
            that.initChooseActive()
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      initChooseActive() {
        var that = this
        that.dataList = that.dataList.filter(function (item) {
          item.active = false
          if(!that.isEmpty(that.chooseList)) {
            that.chooseList.forEach(function (child) {
              if(child.id == item.id) {
                item.active = true
              }
            })
          }
          return true
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      beforeUpload(file) {
        const fileType = typeof this.fileType === 'number' ? [this.fileType] : this.fileType;
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        const isVideo = file.type === 'video/mp4';
        const isAudio = file.type === 'video/mp3';
        const isLt2M = file.size / 1024 / 1024 < 5;
        // 需要服务器也设置可上传的文件类型
        if ((isJPG && !fileType.includes(1)) || (isVideo && !fileType.includes(2)) || (isAudio && !fileType.includes(3))) {
          this.$message.error('上传文件格式不正确');
          return false
        }
        // if (!isLt2M) {
        //   this.$message.error('上传文件大小不能超过 5MB!');
        //   return false
        // }

        this.loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0.8)'
        })

        this.picdata['file_type'] = isJPG && 1 || isVideo && 2 || isAudio && 3

        return true;
      },
      uploadSuccess(res, file) {
        var that = this
        if(res.errcode == 0) {
          that.success('上传成功')
          that.search.current_page = 1
          that.getList()
        } else {
          that.$message.error(res.errmsg)
        }

        this.loadingInstance.close()
      },
      uploadError(res, file) {
        this.$message.error('上传失败，请重新上传!');
        this.loadingInstance.close()
      },
      handleCurrentChange(val) {
        this.chooseList = []
        this.search.current_page = val
        this.getList()
      },
      chooseFile(obj) {
        var that = this
        if(that.fileNum == 1) {
          that.chooseList = [obj]
        } else {
          var isAdd = true
          that.chooseList = that.chooseList.filter(function (item) {
            if(item.id == obj.id) {
              isAdd = false
              return false
            } else {
              return true
            }
          })
          if(isAdd) {
            if(that.chooseList.length >= that.fileNum) {
              this.$message.error('最多只能选择'+that.fileNum+'张!');
              return false
            }
            that.chooseList.push(obj)
          }
        }
        this.initChooseActive()
      },
      chooseOk() {
        if(this.isEmpty(this.chooseList)) {
          this.$message.error('请选择图片!')
        } else {
          if(this.fileNum == 1) {
            this.chooseFunc(this.chooseList[0])
          } else {
            this.chooseFunc(this.chooseList)
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
